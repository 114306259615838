import { Alert, Backdrop, Box, Button, Checkbox, CircularProgress, FormControlLabel, Modal, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import guestsData from '../guests.json'; // Import JSON data

interface PartyData {
  [email: string]: {
    partyLeader: string;
    guests?: string[];
    eveningOnly: boolean;
  };
}

interface RSVPFormData {
  email: string;
  guests: {
    guest: string;
    attendingCeremony: boolean;
    attendingEvening: boolean;
  }[];
  eveningOnly: boolean;
  songChoice: string;
  message: string;
  notes: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
}

function RSVPForm({ open, onClose }: Props) {
  const [formData, setFormData] = useState<RSVPFormData>({
    email: '',
    guests: [],
    songChoice: '',
    message: '',
    notes: '',
    eveningOnly: false
  });

  const [submitStatus, setSubmitStatus] = useState<'success' | 'error' | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [guestLoading, setGuestLoading] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleGetGuestList = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setGuestLoading(true);

    // Fallback to using local JSON data
    const partyData: PartyData = guestsData;
    const party = partyData[formData.email.toLowerCase()];

    try {
      const apiResponse = await fetch('https://2euuygd2kkfe3o6bgv4eyg6khi0uksgg.lambda-url.eu-west-2.on.aws/data');
      if (apiResponse.ok) {
        const apiData = await apiResponse.json();
        const apiParty = apiData[formData.email.toLowerCase()];
        if (apiParty) {
          const initialGuests = apiParty.guests?.map((guest: any) => ({
            guest: guest["Name"],
            attendingCeremony: guest["Ceremony"] === "yes",
            attendingEvening: guest["Evening"] === "yes",
          })) || [];
          setFormData({
            ...formData,
            guests: initialGuests,
            songChoice: apiParty.songs || '',
            notes: apiParty.notes || '',
            eveningOnly: party.eveningOnly
          });
          setGuestLoading(false);
          return;
        }
      }
    } catch (error) {
      console.error('Error fetching data from API:', error);
    }

    // Fallback to using local JSON data
    if (party) {
      const initialGuests = party.guests?.map(guest => ({
        guest,
        attendingCeremony: false,
        attendingEvening: false,
      })) || [];
      initialGuests.push({
        guest: party.partyLeader,
        attendingCeremony: false,
        attendingEvening: false,
      });
      setFormData({
        ...formData,
        guests: initialGuests,
        eveningOnly: party.eveningOnly,
      });
    } else {
      console.error('Email not found in data.');
    }

    setGuestLoading(false);
  };



  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://2euuygd2kkfe3o6bgv4eyg6khi0uksgg.lambda-url.eu-west-2.on.aws/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formData.email.toLowerCase(),
          guests: formData.guests.map(guest => ({
            name: guest.guest,
            ceremony: formData.eveningOnly ? "no" : guest.attendingCeremony ? 'yes' : 'no',
            evening: guest.attendingEvening ? 'yes' : 'no',
          })),
          songs: formData.songChoice,
          notes: formData.notes,
        }),
      });

      if (response.ok) {
        setSubmitStatus('success');
        onClose(); // Close modal on successful submission
      } else {
        setSubmitStatus('error');
      }
    } catch (error) {
      console.error('Error submitting RSVP:', error);
      setSubmitStatus('error');
    }
    setLoading(false);
  };

  const handleCheckboxChange = (guest: string, event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const updatedGuests = formData.guests.map(g => {
      if (g.guest === guest) {
        return {
          ...g,
          [name]: checked,
        };
      }
      return g;
    });
    setFormData({
      ...formData,
      guests: updatedGuests,
    });
  };

  const handleSelectAll = (checked: boolean) => {
    const updatedGuests = formData.guests.map(g => ({
      ...g,
      attendingCeremony: formData.eveningOnly ? false : checked,
      attendingEvening: checked,
    }));
    setFormData({
      ...formData,
      guests: updatedGuests,
    });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: 600,
          width: "90%", // Adjusted for better responsiveness
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          overflowY: 'auto', // Make the content scrollable vertically
          maxHeight: '65vh', // Limit the height of the modal to 80% of the viewport height
          '@media (max-width: 600px)': {
            // Adjust styles for screens smaller than 600px wide (typical for mobile devices)
            width: "95%", // Adjusted width for smaller screens
            fontSize: '14px', // Adjusted font size for better readability on smaller screens
          }
        }}
      >
        <form onSubmit={handleGetGuestList}>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Enter your email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <Box display={"flex"}>
            <Button type="submit" variant="contained" sx={{ mt: 2, mr: 1 }}>Get Guest List</Button>
            {guestLoading && <CircularProgress sx={{ mt: 2, marginLeft: "10px" }} />}
          </Box>
        </form>

        {submitStatus === 'success' && (
          <Alert severity="success" sx={{ mt: 2 }}>RSVP submitted successfully.</Alert>
        )}
        {submitStatus === 'error' && (
          <Alert severity="error" sx={{ mt: 2 }}>Failed to submit RSVP. Please try again later.</Alert>
        )}

        {formData.guests.length > 0 && (
          <Box mt={2}>
            <Typography variant="h6">Guest List:</Typography>
            <FormControlLabel
              control={<Checkbox onChange={(e) => handleSelectAll(e.target.checked)} />}
              label="Select All"
              checked={formData.eveningOnly ? formData.guests.every(guest => guest.attendingEvening) : formData.guests.every(guest => guest.attendingCeremony && guest.attendingEvening)}
            />
            <ul>
              {formData.guests.map(({ guest, attendingCeremony, attendingEvening }) => (
                <li key={guest}>
                  <Typography>{guest}</Typography>
                  {!formData.eveningOnly && (<FormControlLabel
                    control={
                      <Checkbox
                        checked={attendingCeremony}
                        onChange={(event) => handleCheckboxChange(guest, event)}
                        name="attendingCeremony"
                      />
                    }
                    label={`Ceremony`}
                  />)}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={attendingEvening}
                        onChange={(event) => handleCheckboxChange(guest, event)}
                        name="attendingEvening"
                      />
                    }
                    label={`Evening`}
                  />
                </li>
              ))}
            </ul>
            <TextField
              fullWidth
              multiline
              id="songChoice"
              name="songChoice"
              label="Song Choice(s)"
              value={formData.songChoice}
              onChange={handleChange}
              sx={{ mt: 2 }}
            />
            <TextField
              fullWidth
              multiline
              id="notes"
              name="notes"
              label="Notes (Dietary Requirements, Message, ect)"
              value={formData.notes}
              onChange={handleChange}
              sx={{ mt: 2 }}
            />
            <Box display={"flex"}>
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{ mt: 2 }}
              >
                Submit RSVP
              </Button>
              {loading && <CircularProgress sx={{ mt: 2, marginLeft: "10px" }} />}
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
}

export default RSVPForm;
