import { AirplanemodeActive, Diamond, FavoriteRounded, KeyOutlined, PetsOutlined } from "@mui/icons-material";
import { Container, Typography } from "@mui/material";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const TimelineElement = ({ title, date, icon, imageSrc }: any) => (
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgba(1000,1000,1000,0.2)', color: '#fff', boxShadow: "none" }}
    contentArrowStyle={{ borderRight: '7px solid  rgba(1000,1000,1000,0.2)' }}
    iconStyle={{ background: 'gold', color: '#000' }}
    icon={icon}
  >
    <h3 style={{
      fontFamily: "DisneyFont", fontSize: 32,
      letterSpacing: '3px',
      backgroundImage: `linear-gradient(
          to right,
          #cb9b51 0%,
          #f6e27a 45%,
          #f6f2c0 50%,
          #f6e27a 55%,
          #cb9b51 78%,
          #462523 100%
        )`,
      color: 'transparent',
      WebkitBackgroundClip: 'text'
    }} className="vertical-timeline-element-title">{title}</h3>
    <h4 style={{ fontFamily: "DisneyFont", fontSize: 22 }} className="vertical-timeline-element-subtitle">{date}</h4>
    <br />
    {imageSrc && <img style={{ borderRadius: "10px" }} height={"100%"} width={"100%"} src={imageSrc} alt="Timeline Event" />}
  </VerticalTimelineElement>
);

const About = () => {
  return (
    <Container maxWidth="xl" style={{ marginTop: '40px', textAlign: 'center' }}>
      <Typography variant="h3" gutterBottom>
        Once upon a time...
      </Typography>
      <VerticalTimeline lineColor="gold">
        <TimelineElement
          title="Our love story began!"
          date="16 August 2013"
          icon={<FavoriteRounded />}
          imageSrc="./images/meet.jpg"
        />
        <TimelineElement
          title="Our first holiday abroad alone...Traveling to Italy"
          date="July 7th 2017"
          icon={<AirplanemodeActive />}
          imageSrc="./images/italy.jpg"
        />
        <TimelineElement
          title="Our first disney trip together...Traveling to Disneyworld"
          date="April 13th 2018"
          icon={<img alt="Disney castle" src="./icons/disney_castle.png" height={"90%"} width={"90%"} />}
          imageSrc="./images/disney1.jpg"
        />
        <TimelineElement
          title="Our first home"
          date="29th September 2018"
          icon={<KeyOutlined />}
          imageSrc="./images/key.jpg"
        />
        <TimelineElement
          title="We brought Riley home!"
          date="23rd July 2020"
          icon={<PetsOutlined />}
          imageSrc="./images/dog.jpg"
        />
        <TimelineElement
          title="Ryan got down on one knee!"
          date="27th December 2021"
          icon={<Diamond />}
          imageSrc="./images/banner.jpg"
        />
        <TimelineElement
          title="The Day we get Married!!!"
          date="21st June 2025"
          icon={<FavoriteRounded />}
          imageSrc="./images/disney2.jpg"
        />
      </VerticalTimeline>
    </Container>
  );
};

export default About;
