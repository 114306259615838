import { Button, Card, CardContent, Container, Typography } from "@mui/material";
import { useState } from "react";
import RSVPForm from "./RSVPForm";


const Rsvp = () => {

    const [showRSVP, setShowRSVP] = useState(false);

    const toggleRSVP = () => {
        setShowRSVP(!showRSVP);
    };

    return (
        <><Container maxWidth="md" style={{ marginTop: '40px', textAlign: 'center' }}>
            <Card sx={{ background: "rgba(1000,1000,1000,0.2)", color: "white"}}>
                <CardContent>
                    <Typography variant="h3" gutterBottom>
                    You Are Cordially Invited
                    </Typography>
                    <Typography variant="body2">
                        <CardContent>
                            <Typography variant="body2">
                                To confirm your attendance to the wedding, just enter your email you received the invite to. Your name will appear and then just tell whether or not you'll join us in our Happily Ever After!



                                Please can you leave a note of your 'Couple' song or any fun song requests, we would love to add these to our list for the evening antics!



                                If you have any dietary requirements please leave them in the notes box and we will let the venue know of any adjustments needed. (Multiple choice food options to come)
                            </Typography>
                            <div>
                                <br />
                                <Button variant="contained" sx={{fontSize: "38px", fontFamily: "DisneyFont", letterSpacing: "5px"}} onClick={toggleRSVP}>RSVP</Button>
                                <RSVPForm open={showRSVP} onClose={toggleRSVP} />
                            </div>
                        </CardContent>
                    </Typography>
                </CardContent>
            </Card>
        </Container></>
    );
};

export default Rsvp;
