import { Card, CardContent, Container, Typography, Avatar, Grid } from "@mui/material";
import "./Party.css";

// Define an object mapping each person's name to their avatar image URL
const avatarImages = {
    "Amy Louise Davey - Bride": "./images/avatars/amy.jpg",
    "Ryan Victor Abercrombie Sutcliffe - Groom": "./images/avatars/ryan.jpg",
    "Tracy Davey - Mother of the Bride": "./images/avatars/tracy.jpg",
    "Darren Davey - Father of the Bride": "./images/avatars/darren.jpg",
    "Michelle Sutcliffe - Mother of the Groom": "./images/avatars/mum.jpg",
    "Vic Sutcliffe - Father of the Groom": "./images/avatars/dad.jpg",
    "Martha Reakes-Bracey - Maid of Honour": "./images/avatars/martha.jpg",
    "Sam Hendy - Best Man": "./images/avatars/sam.jpg",
    "Kelly Bracey - Bridesmaid": "./images/avatars/kelly.jpg",
    "Connor Slim - Groomsman": "./images/avatars/connor.jpg",
    "Chloe Dowle - Bridesmaid": "./images/avatars/chloe.jpg",
    "Luke Sutcliffe - Groomsman": "./images/avatars/luke.png",
    "Jo Hendy - Bridesmaid": "./images/avatars/jo.png"
};

const Party = () => {
    const entries = Object.entries(avatarImages);
    return (
        <Container maxWidth="md" style={{ marginTop: '40px'}}>
            <Card style={{  background: "rgba(1000,1000,1000,0.2)"}}>
                <CardContent>
                    <Typography variant="h3" gutterBottom>
                        Royal Entourage
                    </Typography>
                    <CardContent>
                        <Grid container spacing={3} className="avatar-grid">
                            {/* First row with two items */}
                            <Grid item xs={6} sm={6}>
                                <div className="avatar-container">
                                    <Avatar src={entries[0][1]} className={'avatar'} />
                                    <Typography style={        {backgroundImage: `linear-gradient(
          to right,
          #cb9b51 0%,
          #f6e27a 45%,
          #f6f2c0 50%,
          #f6e27a 55%,
          #cb9b51 78%,
          #cb9b51 100%
        )`,
        color: 'transparent',
        WebkitBackgroundClip: 'text' }} variant="body1" component="div">{entries[0][0]}</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <div className="avatar-container">
                                    <Avatar src={entries[1][1]} className={'avatar'} />
                                    <Typography style={        {backgroundImage: `linear-gradient(
          to right,
          #cb9b51 0%,
          #f6e27a 45%,
          #f6f2c0 50%,
          #f6e27a 55%,
          #cb9b51 78%,
          #cb9b51 100%
        )`,
        color: 'transparent',
        WebkitBackgroundClip: 'text' }} variant="body1" component="div">{entries[1][0]}</Typography>
                                </div>
                            </Grid>

                            {/* Subsequent rows with three items */}
                            {entries.slice(2).map(([name, avatarUrl], index) => (
                                <Grid item xs={6} sm={4} key={name}>
                                    <div className="avatar-container">
                                        <Avatar src={avatarUrl} className={'avatar'} />
                                        <Typography style={        {backgroundImage: `linear-gradient(
          to right,
          #cb9b51 0%,
          #f6e27a 45%,
          #f6f2c0 50%,
          #f6e27a 55%,
          #cb9b51 78%,
          #cb9b51 100%
        )`,
        color: 'transparent',
        WebkitBackgroundClip: 'text' }} variant="body1" component="div">{name}</Typography>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </CardContent>
                </CardContent>
            </Card>
        </Container>
    );
};

export default Party;