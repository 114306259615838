import { Card, CardContent, Container, Typography } from "@mui/material";


const Food = () => {
    return (
        <><Container maxWidth="md" style={{ marginTop: '40px', textAlign: 'center' }}>
            <Card style={{background: "rgba(1000,1000,1000,0.2)"}}>
                <CardContent>
                    <Typography variant="h3" gutterBottom>
                        Enchanted Feast
                    </Typography>
                    <Typography variant="body2">
                        <CardContent>
                            <Typography variant="h2">Coming Soon!</Typography>
                               
                            
                        </CardContent>
                    </Typography>
                </CardContent>
            </Card>
        </Container></>
    );
};

export default Food;
