import { Card, CardContent, Container, Typography } from "@mui/material";

const Honeymoon = () => {

    return (
        <Container maxWidth="md" style={{ marginTop: '40px', textAlign: 'center' }}>
            <Card sx={{ background: "rgba(1000,1000,1000,0.2)", color: "white"}}>
                <CardContent>
                    <Typography variant="h3" gutterBottom>
                    Happily Ever After Honeymoon
                    </Typography>
                    <Typography variant="body2">
                        <CardContent>
                            <Typography variant="body2">
                                <p style={{ marginBottom: '1rem', fontStyle: 'italic' }}>Dear Friends and Family,</p>

                                <p style={{ marginBottom: '1rem' }}>We hope this message finds you well and eagerly anticipating the festivities of our upcoming wedding day. As we count down the days until we say "I do," we've been reflecting on the love and support we've received from all of you, and it fills our hearts with immense gratitude.</p>

                                <p style={{ marginBottom: '1rem' }}>With our wedding approaching, we've been asked what we might like as gifts. After much thought, we've come to a decision that we hope you'll understand and support. We would deeply appreciate contributions towards our honeymoon fund, as it would help us create unforgettable memories as we embark on this new chapter together.</p>

                                <p style={{ marginBottom: '1rem' }}>However, please know that your presence at our wedding is truly what matters most to us. Whether you choose to give towards our honeymoon fund, or simply join us in celebrating our love, your support means everything to us.</p>

                                <p style={{ marginBottom: '1rem' }}>We're so grateful to have you in our lives, and we can't wait to share this special day with you, surrounded by love and joy.</p>

                                <p style={{ marginBottom: '1rem', fontStyle: 'italic' }}>With love and appreciation,</p>
                                <p style={{ fontWeight: 'bold', fontFamily: "DisneyFont", fontSize: "38px", letterSpacing: "5px" }}>Amy & Ryan</p>
                            </Typography>
                        </CardContent>
                    </Typography>
                </CardContent>
            </Card>

            {/* Placeholder image */}
            <img src={"./images/collage.jpg"} alt="Honeymoon Destination" style={{ maxWidth: '100%', marginTop: '20px' }} />
        </Container>
    );
};

export default Honeymoon;
