// CountdownTimer.tsx
import moment from 'moment';
import React, { useEffect, useState } from 'react';

interface Props {
    weddingDate: string; // Format: "YYYY-MM-DDTHH:mm:ss"
}

const CountdownTimer: React.FC<Props> = ({ weddingDate }) => {
    const [timeLeft, setTimeLeft] = useState<string>('');

    useEffect(() => {
        const intervalId = setInterval(() => {
            const now = moment();
            const end = moment(weddingDate);
            const duration = moment.duration(end.diff(now));

            const days = Math.floor(duration.asDays());
            const hours = duration.hours();
            const minutes = duration.minutes();
            const seconds = duration.seconds();

            setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);

            if (duration.asMilliseconds() <= 0) {
                clearInterval(intervalId);
                setTimeLeft('Wedding Time!');
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [weddingDate]);

    return <div style={{
        fontSize: "42px",
        fontFamily: 'DisneyFont',
        letterSpacing: '5px',
        fontWeight: 'bold',
        backgroundImage: `linear-gradient(
            to right,
            #cb9b51 0%,
            #f6e27a 45%,
            #f6f2c0 50%,
            #f6e27a 55%,
            #cb9b51 78%,
            #462523 100%
          )`,
        color: 'transparent',
        WebkitBackgroundClip: 'text',
    }}>{timeLeft}</div>;
};

export default CountdownTimer;
