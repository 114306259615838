import { Card, CardContent, CardMedia, Container, Typography } from "@mui/material";

const Stay = () => {
    return (
        <Container maxWidth="md" style={{ marginTop: '40px', textAlign: 'center' }}>
            <Card sx={{ background: "rgba(1000,1000,1000,0.2)", color: "white"}}>
                <CardContent>
                    <Typography variant="h2" gutterBottom>
                    Fairy Tale Lodgings
                    </Typography>
                    <Typography variant="body2">
                        We wanted to take a moment to address accommodation options for those of you who will be joining us to celebrate. We understand that our chosen venue may be on the pricier side, and we want to ensure that everyone feels comfortable and has suitable options for their stay.
                    </Typography>
                </CardContent>
            </Card>

            {/* Accommodation Option 1: Local Hotels and Inns */}
            <Card sx={{ background: "rgba(1000,1000,1000,0.2)", color: "white", marginTop: '20px'}}>
                <CardMedia
                    component="img"
                    height="300"
                    image="./images/harbour.jpg"
                    alt="Local Hotels and Inns"
                />
                <CardContent>
                    <Typography variant="h4" gutterBottom>
                        Local Hotels and Inns
                    </Typography>
                    <Typography variant="body2">
                        There are several charming hotels and inns within a short distance from the venue. We've compiled a list of recommendations, including options at different price points, to suit everyone's needs.
                        <ul>
                                <Typography variant="body2">The Harbour Hotel: <a href="https://www.harbourhotels.co.uk/st-ives">Link</a></Typography>
                                <Typography variant="body2">The Bay Hotel: <a href="https://www.google.com/travel/search?ts=CAESCAoCCAMKAggDGhwSGhIUCgcI6A8QBRgFEgcI6A8QBRgGGAEyAhAAKgcKBToDR0JQ&qs=CAEyE0Nnb0kyZGE1ajYzRmp1MExFQUU4CkIJCcpgJZuZM_MfQgkJWWvu0So62gtCCQmC2kbFsdRD1FpLMkmqAUYQASoKIgZob3RlbHMoADIfEAEiGwiT0VEJFkraxXKv4g3DOHsroO5N56yqtOG6XDIVEAIiEWhvdGVscyBpbiBzdCBpdmVz&utm_campaign=sharing&utm_medium=link_btn&utm_source=htls">Link</a></Typography>
                                <Typography variant="body2">Premier Inn: <a href="https://www.premierinn.com/gb/en/hotels/england/cornwall/hayle/hayle.html?cid=KNC_Gen_G_UK_UK_Eng_Hayle_Null_Null&mckv=s_dc%7Cpcrid%7C691614575757%7Ckword%7Chotel%20st%20ives%7Cmatch%7Ce%7Cplid%7C%7Cpgrid%7C118286951734%7Cptaid%7Ckwd-308475838228%7C&s_kwcid=AL!9693!3!691614575757!e!!g!!hotel%20st%20ives!12563843233!118286951734&ef_id=Cj0KCQjwiMmwBhDmARIsABeQ7xS8Qmf5e7AoWYwRck-Ccw5BVreMMgIlZifsS5hPRkg1Tlm5BKh4F5AaAuxAEALw_wcB:G:s&gad_source=1&gclid=Cj0KCQjwiMmwBhDmARIsABeQ7xS8Qmf5e7AoWYwRck-Ccw5BVreMMgIlZifsS5hPRkg1Tlm5BKh4F5AaAuxAEALw_wcB&BRAND=PI">Link</a></Typography>
                        </ul>
                    </Typography>
                </CardContent>
            </Card>

            {/* Accommodation Option 2: Airbnb or Vacation Rentals */}
            <Card sx={{ background: "rgba(1000,1000,1000,0.2)", color: "white", marginTop: '20px'}}>
                <CardMedia
                    component="img"
                    image="./images/airbnb.png"
                    sx={{maxWidth: "500px", margin: "0 auto"}}
                    alt="Airbnb or Vacation Rentals"
                />
                <CardContent>
                    <Typography variant="h4" gutterBottom>
                        Airbnb or Vacation Rentals
                    </Typography>
                    <Typography variant="body2">
                        For those who prefer a more personalized and perhaps homier experience, there are plenty of Airbnb listings and vacation rentals in the area. From cozy cottages to spacious apartments, there's something for everyone.
                        <ul>
                                <Typography variant="body2">Airbnb Link 1: <a href="https://www.airbnb.co.uk/s/St-Ives--England--United-Kingdom/homes?adults=2&children=0&checkin=2025-06-20&checkout=2025-06-22">Link</a></Typography>
                                <Typography variant="body2">Airbnb Link 2: <a href="https://www.airbnb.co.uk/rooms/609412855637088774?_set_bev_on_new_domain=1709926457_MmY2MTUyZTZkZWIx&source_impression_id=p3_1709929069_A66EuQlH%2Bu5eqeXr">Link</a></Typography>
                                <Typography variant="body2">Airbnb Link 3: <a href="https://www.airbnb.co.uk/rooms/37309413?adults=2&children=2&check_in=2025-06-20&check_out=2025-06-22&source_impression_id=p3_1712517066_N%2Fm%2BGAzNFE1UqMRT&previous_page_section_name=1000&federated_search_id=50d4ef12-86a9-49c3-b812-c0002189a829">Link</a></Typography>
                        </ul>
                    </Typography>
                </CardContent>
            </Card>

            {/* Accommodation Option 3: Camping or Glamping */}
            <Card sx={{ background: "rgba(1000,1000,1000,0.2)", color: "white", marginTop: '20px'}}>
                <CardMedia
                    component="img"
                    height="300"
                    image="./images/caravan.jpg"
                    alt="Camping or Glamping"
                />
                <CardContent>
                    <Typography variant="h4" gutterBottom>
                        Camping or Glamping
                    </Typography>
                    <Typography variant="body2">
                        If you're feeling adventurous and want to make a weekend out of it, there are camping and glamping sites nearby where you can immerse yourself in nature before joining us for the festivities.
                        <ul>
                                <Typography variant="body2">Hoseasons Park: <a href="https://www.hoseasons.co.uk/accom/tolroy-manor-tolr?adult=2&child=0&infant=0&pets=0&range=3&nights=3&accommodationType=holiday-parks&regionName=St+Ives&regionId=22103&start=20-06-2025&index=holiday-parks%2Cengland%2Ccornwall&accommodationTypes=&features=&siteFeatures=&page=1&sort=priceasc&newflex=true">Link</a></Typography>
                                <Typography variant="body2">Ayr Park: <a href="https://www.ayrholidaypark.co.uk/">Link</a></Typography>
                                <Typography variant="body2">Lovat Parks: <a href="https://lovatparks.com/locations/cornwall/parbola-holiday-park/holidays?gad_source=1&gclid=Cj0KCQjwiMmwBhDmARIsABeQ7xR0RTOy9iphxqHKoqAxLskZ5jEIHcJqJ5oInLa6fk3q-xhCZTYvAFsaAuddEALw_wcB">Link</a></Typography>    
                        </ul>
                    </Typography>
                </CardContent>
            </Card>

             {/* Accommodation Option 4: Holiday Cottages */}
             <Card sx={{ background: "rgba(1000,1000,1000,0.2)", color: "white", marginTop: '20px'}}>
                <CardMedia
                    component="img"
                    height="300"
                    image="./images/holidaycot.png"
                    alt="Holiday Cottages"
                />
                <CardContent>
                    <Typography variant="h4" gutterBottom>
                        Holiday Cottages
                    </Typography>
                    <Typography variant="body2">
                        Experience the charm of the seaside with our selection of holiday cottages. Perfect for a cozy retreat after a day of celebration.
                        <ul>
                                <Typography variant="body2">Holiday Cottages: <a href="https://www.holidaycottages.co.uk/cottages?getSearch=true&From=20%2F06%2F2025&Duration=2&Town=St+Ives+Harbour&PartySize=0&daysf=1&year=2025&SortBy=Relevance&rpp=12&page=1&viewmap=false&period=9">Link</a></Typography>
                        </ul>
                    </Typography>
                </CardContent>
            </Card>
        </Container>
    );
};

export default Stay;
