import { Card, CardContent, Container, Divider, Typography, useMediaQuery } from "@mui/material";
import { Parallax } from "react-parallax";
import CountdownTimer from "../CountdownTimer";

const Welcome = () => {
    // Set your wedding date here
    const weddingDate = '2025-06-21T11:45:00';
    const isSmallDevice = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  
    const textSize = isSmallDevice ? '20px' : '28px';
    const headerSize = isSmallDevice ? 'h5' : 'h3';


    return (
        <>
            <Typography variant="h1" component="h1" sx={isSmallDevice ? { fontSize: "50px" } : {}}>
                Amy and Ryan's
            </Typography>
            <Typography variant="h2" component="h2" gutterBottom sx={isSmallDevice ? { fontSize: "50px" } : {}}>
                Happily Ever After
            </Typography>
            <CountdownTimer weddingDate={weddingDate} />
            <Parallax bgImage="/images/banner.jpg" strength={200}>
                <div style={{ height: '70vh' }}>

                </div>
            </Parallax >
            <Container maxWidth="md" style={{ marginTop: '40px', marginBottom: '40px', textAlign: 'center' }}>
      <Card style={{
        background: "rgba(1000,1000,1000,0.2)",
        borderStyle: "solid",
        borderWidth: isSmallDevice ? "50px" : "100px",
        borderImage: 'url("./images/border.png") 800',
        borderImageSlice: "fill"
      }}>
        <CardContent>
          <Typography variant={headerSize} gutterBottom>
            Wedding Itinerary
          </Typography>
          <Typography variant="body1" style={{ fontSize: textSize }}>
            <Typography style={{
              backgroundImage: `linear-gradient(
                to right,
                #cb9b51 0%,
                #f6e27a 45%,
                #f6f2c0 50%,
                #f6e27a 55%,
                #cb9b51 78%,
                #cb9b51 100%
              )`,
              color: 'transparent',
              WebkitBackgroundClip: 'text',
              fontSize: textSize
            }} variant="body1">
              <strong>Venue:</strong> The Harbour Hotel, St Ives
              <Divider variant="middle" />
              <strong>Guest Arrival Time:</strong> 11:00 AM
              <Divider variant="middle" />
              <strong>Wedding Ceremony:</strong> 12:00 PM
              <Divider variant="middle" />
              <strong>Wedding Breakfast:</strong> 2:30 PM
              <Divider variant="middle" />
              <strong>Evening Reception:</strong> 7:00 PM
              <Divider variant="middle" />
              <strong>End of Wedding:</strong> 12:00 AM
            </Typography>
          </Typography>
        </CardContent>
      </Card>
    </Container>              <Parallax bgImage="./images/stives.jpg" strength={200}>
                <div style={{ height: '70vh' }}>

                </div>
            </Parallax ></>
    );
};

export default Welcome;
